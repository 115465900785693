<template>
  <div>
    <app-breadcrumb/>
    <div class="page-container orders-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="80px" size="mini">
          <el-form-item label="单据编号:">
            <el-input v-model.trim="listQuery.orderNo" style="width:170px;"/>
          </el-form-item>
          <el-form-item v-if="isAgent" label="顾客编号:">
            <el-input v-model.trim="listQuery.memberNo" style="width:170px;"/>
          </el-form-item>
          <el-form-item v-if="isAgent" label="顾客姓名:">
            <el-input v-model.trim="listQuery.memberName" style="width:170px;"/>
          </el-form-item>
          <el-form-item label="结算:">
            <el-select v-model="listQuery.settle" clearable style="width:170px;">
              <el-option v-for="item in settleOptions" :key="item.code" :label="$lt(initDict.settle, item.code)"
                         :value="item.code"/>
            </el-select>
          </el-form-item>
          <el-form-item label="期次:">
            <el-select v-model="listQuery.week" clearable style="width:170px;">
              <el-option v-for="item in weeks" :key="item.chinaWWeek" :label="item.chinaWWeek" :value="item.chinaWWeek"/>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left:10px;">
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table v-loading="loading" :data="list" style="width: 100%" size="mini" border fit stripe>
        <el-table-column label="操作" width="60" header-align="center" align="left">
          <template slot-scope="{row}">
            <div class="opts">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <i class="button el-icon-search" @click="handleView(row)"/>
              </el-tooltip>
              <el-tooltip
                  class="item"
                  effect="dark"
                  content="支付"
                  placement="top"
                  v-if="row.agentNo === userCode && row.status === '0'"
              >
                <i class="button el-icon-check" style="color: #67C23A" @click="handlePay(row)"/>
              </el-tooltip>
              <el-tooltip
                  v-if="row.periodWeek === row.startWeek && row.checkCode === userCode && row.orderType!==17 && row.orderType !=26 && (row.generateMethod  ===1 || row.generateMethod  === 2 ) && row.startWeek === row.periodWeek && 'isAgent' && row.locked !='1' && row.sendFlag != '1' "
                  class="item" effect="dark" content="删除" placement="top">
                <i class="button delete el-icon-delete" @click="handleDelete(row)"/>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="orderNo" width="130" align="center"/>
        <el-table-column label="单据类型" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.orderType, row.orderType) }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="isAgent" label="购货人" align="center">
          <template slot-scope="{row}">
            <p>{{ row.memberNo }}</p>
            <p>{{ row.memberName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="金额" align="center">
          <template slot-scope="{row}">
            <span>{{ row.orderType === 21 ? (row.totalAmount / 2).toFixed(2) : row.totalAmount.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="换货积分" prop="totalpointa0" align="center"/>
        <el-table-column label="期次" prop="periodWeek" align="center"/>
        <el-table-column label="结算" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.settle, row.locked || 0) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="原订单" prop="cancelOrderNo" align="center"/>
        <el-table-column label="创建时间" prop="createdTime" width="140" align="center"/>
   
<!--        <el-table-column label="积分或实物" width="100" align="center">-->
<!--          <template slot-scope="{row}">-->
<!--            <span>{{row.isH0orgood === 0 ? '积分' : (row.isH0orgood === 1 ? '实物' : '')}}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
<!--      <p style="color: #008800">总计金额:<span style="color: #FF0000">{{totalamount2}}</span>&nbsp;&nbsp;&nbsp;&nbsp;总计消费指数:<span style="color: #FF0000">{{totalamount1}}</span></p>-->
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Pagination from '@/components/Pagination'
import {fetchOrders, deleteOrder, payByOrderNo} from '@/api/order/index'
import {getChinaWweek2} from "@/api/member/pre-bonus";

export default {
  name: 'OrderList',
  components: {Pagination},
  data() {
    return {
      totalamount2:null,
      totalamount1:null,
      loading: false,
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        orderNo: undefined,
        memberNo: undefined,
        memberName: undefined,
        rightNo: undefined,
        settle: undefined,
        week: undefined,
        isH0orgood: undefined
      },
      weeks: [],
      initDict: {
        orderType: 'po.allordertype.cn',
        settle: 'pdmanifestsettle.receiptstatus'
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAgent',
      'userCode'
    ]),
    settleOptions() {
      return this.$ll(this.initDict.settle)
    }
  },
  mounted() {
    this.getWeeks()
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.totalamount2 = 0
      this.totalamount1 = 0
      fetchOrders(this.listQuery).then(res => {
        this.list = res.data.records
        this.total = +res.data.total || 0
        this.totalamount2 = res.data.records[0].totalamount2
        this.totalamount1 = res.data.records[0].totalamount1
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    },
    getWeeks() {
      getChinaWweek2().then(res => {
        this.weeks = res.data
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleView(row) {
      this.$router.push(`/order/detail?id=${row.id}`)
    },
    // 支付
    handlePay(row) {
      this.$confirm('确定支付?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        payByOrderNo({id: row.id}).then(() => {
          this.$message.success('支付成功')
          this.getList()
        }).catch(err => {
          console.warn(err)
        })
      }).catch(() => {
      })
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除订单:' + row.orderNo + ', 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteOrder({id: row.id}).then(() => {
          this.$message.success(`订单${row.orderNo}已删除`)
          this.getList()
        }).catch(err => {
          console.warn(err)
        })
      }).catch(() => {
      })
    }
  }
}
</script>

<style>

</style>
